import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { Page } from '../../base/Page'
import { Styles } from '../../base/Styles'
import { useOrders } from '../../base/useDbOrders'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

export default function Orders() {
  const { orders } = useOrders()
  const navigate = useNavigate()

  console.log('allorders', orders)

  return (
    <Page title='Orders'>
      <button
        type='button'
        className={Styles.button.default}
        onClick={() => navigate('/products/add')}
      >
        Add
      </button>
      {/* <div>
        <p>
          {error && <>Error: {error}</>}
          {loading && <span>List: Loading...</span>}
        </p>
      </div> */}
      <div className='flex flex-row p-4 rounded bg-white'>
        {(!orders || orders.length === 0) && <div>No orders</div>}
        {orders && (
          <div className='bg-white'>
            <div>
              {orders.map((order) => {
                const date = order.orderDate ? format(new Date(Number(order.orderDate)), 'PPp') : ''
                return (
                  <div key={order.orderId} className='border pb-1 border-b-8'>
                    <div className='flex justify-between'>
                      <div className='text-xs'>{order.orderId}</div>
                      <div className='font-semibold'>{date}</div>
                    </div>
                    <div className='flex gap-2'>
                      <div className='font-semibold'>{order.deliveryName}</div>
                      <div>{order.deliveryAddress}</div>
                      <div>{order.deliveryPhone}</div>
                    </div>
                    <div className='border flex justify-between'>
                      <div>{order.orderStatus}</div>

                      <div>
                        <span className='font-thin text-xs'>¥</span>
                        <span className='font-bold text-lg'>
                          {new Intl.NumberFormat('ja-JP', { maximumFractionDigits: 0 }).format(
                            order.totalAmount,
                          )}
                        </span>
                      </div>
                      <div>
                        <button
                          id='editButton'
                          className='underline'
                          onClick={() => navigate(`/orders/${order.orderId}`)}
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Page>
  )
}

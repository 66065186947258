import React, { useEffect } from 'react'

import { Styles } from '../base/Styles'
import { Page } from '../base/Page'
import { firebaseApp } from '../firebase'
import { ref, getDatabase, set } from 'firebase/database'
import { useList } from 'react-firebase-hooks/database'
import { InputText } from '../base/InputText'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../base/validateResolver'
import { useForm } from 'react-hook-form'
import { Card } from 'flowbite-react'
import { DB } from '../DB'
import { PickupTimeslots } from '../model/Settings'
import toast from 'react-hot-toast'

const formSchema = Yup.object().shape({
  name: Yup.string().required(),
  valueEn: Yup.string().required(),
  valueJp: Yup.string().required(),
})

const database = getDatabase(firebaseApp)

export default function PickupTimeslot() {
  const [snapshots, loading, error] = useList(ref(database, DB.pickupTimeslots))
  const [data, setData] = React.useState<PickupTimeslots[]>([])
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<PickupTimeslots>({ resolver: useYupValidationResolver(formSchema) })

  useEffect(() => {
    if (snapshots) {
      setData(snapshots.map((s) => s?.toJSON() as PickupTimeslots))
    }
  }, [snapshots])

  const onSubmit = (data: PickupTimeslots) => {
    set(ref(database, DB.pickupTimeslots + '/' + data.name), data)
    reset()
    toast.success('Pickup Timeslots Saved')
  }

  return (
    <Page title='Pickup Timeslots'>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-row gap-2 items-center justify-center border-b-2  border-dotted py-1'>
            <InputText
              {...register('name')}
              name={'name'}
              label='Name'
              error={errors.name?.message}
            />
            <InputText
              {...register('valueEn')}
              name={'valueEn'}
              label='valueEn'
              error={errors.valueEn?.message}
            />
            <InputText
              {...register('valueJp')}
              name={'valueJp'}
              label='valueJp'
              error={errors.valueJp?.message}
            />
            <button type='submit' className={Styles.button.default}>
              Save
            </button>
          </div>
        </form>
        {error && <>Error: {error}</>}
        {loading && <span>List: Loading...</span>}
        {!loading && (
          <>
            <div className='grid grid-cols-4 gap-2 w-full'>
              {data?.map((d) => {
                // eslint-disable-next-line react/jsx-key
                return (
                  <>
                    <div className='py-1 border-b-2 flex items-center'>{d.name}</div>
                    <div className='py-1 border-b-2 flex items-center'>{d.valueEn}</div>
                    <div className='py-1 border-b-2 flex items-center'>{d.valueJp}</div>
                    <div className='py-1 border-b-2 flex items-center'>
                      <button
                        className={Styles.button.link}
                        onClick={() => {
                          setValue('name', d.name)
                          setValue('valueEn', d.valueEn)
                          setValue('valueJp', d.valueJp)
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={Styles.button.link}
                        onClick={() => {
                          set(ref(database, DB.pickupTimeslots + '/' + d.name), null)
                          toast.success('Pickup Timeslot Deleted')
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                )
              })}
            </div>
          </>
        )}
      </Card>
    </Page>
  )
}

import React, { useEffect } from 'react'

import { Styles } from '../base/Styles'
import { Page } from '../base/Page'
import { firebaseApp } from '../firebase'
import { ref, getDatabase, set } from 'firebase/database'
import { useList } from 'react-firebase-hooks/database'
import { InputText } from '../base/InputText'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../base/validateResolver'
import { useForm } from 'react-hook-form'
import { Landmark } from '../model/Landmark'
import { Card } from 'flowbite-react'
import { DB } from '../DB'
import toast from 'react-hot-toast'

const formSchema = Yup.object().shape({
  name: Yup.string().required(),
  landmark: Yup.string().required(),
})

const database = getDatabase(firebaseApp)

export default function Landmarks() {
  const [snapshots, loading, error] = useList(ref(database, DB.landmarks))
  const [data, setData] = React.useState<Landmark[]>([])
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Landmark>({ resolver: useYupValidationResolver(formSchema) })

  useEffect(() => {
    if (snapshots) {
      setData(snapshots.map((s) => s?.toJSON() as Landmark))
    }
  }, [snapshots])

  const onSubmit = (data: Landmark) => {
    set(ref(database, DB.landmarks + '/' + data.name), data)
    reset()
    toast.success('Landmark Saved')
  }

  return (
    <Page title='Categories'>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-row gap-2 items-center justify-center border-b-2  border-dotted py-1'>
            <InputText
              {...register('name')}
              name={'name'}
              label='Name'
              error={errors.name?.message}
            />
            <InputText
              {...register('landmark')}
              name={'landmark'}
              label='Landmark'
              error={errors.landmark?.message}
            />
            <button type='submit' className={Styles.button.default}>
              Save
            </button>
          </div>
        </form>
        {error && <>Error: {error}</>}
        {loading && <span>List: Loading...</span>}
        {!loading && (
          <>
            <div className='grid grid-cols-3 gap-2 w-full'>
              {data?.map((d) => {
                // eslint-disable-next-line react/jsx-key
                return (
                  <>
                    <div className='py-1 border-b-2 flex items-center'>{d.name}</div>
                    <div className='py-1 border-b-2 flex items-center'>{d.landmark}</div>
                    <div className='py-1 border-b-2 flex items-center'>
                      <button
                        className={Styles.button.link}
                        onClick={() => {
                          setValue('name', d.name)
                          setValue('landmark', d.landmark)
                        }}
                      >
                        Edit
                      </button>
                    </div>
                  </>
                )
              })}
            </div>
          </>
        )}
      </Card>
    </Page>
  )
}

import { getDatabase, ref, set } from 'firebase/database'
import { firebaseApp } from '../../firebase'
import { useParams } from 'react-router-dom'
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref as storeRef,
  uploadBytes,
} from 'firebase/storage'
import { Button } from 'flowbite-react'
import { DB } from '../../DB'
import { useProduct } from '../../base/useDbHooks'

const database = getDatabase(firebaseApp)
const storage = getStorage(firebaseApp)

export function ProductImage() {
  const { id } = useParams()
  const { product } = useProduct(id)

  const handleChange = (e: any) => {
    e.preventDefault()
    if (!product) {
      return
    }
    const file = e.target?.files[0]
    if (!file) return

    const storageRef = storeRef(storage, `images/${id}-${file.name}`)
    uploadBytes(storageRef, file, {
      cacheControl: 'public, max-age=2592000, s-maxage=2592000',
    }).then((snapshot) => {
      e.target.value = ''
      getDownloadURL(snapshot.ref).then((url) => {
        product.images = [...Object.values(product.images ?? []), url]
        set(ref(database, DB.products + '/' + id), product)
      })
    })
  }

  const removeImageFromProduct = (imagePath: string) => {
    if (product) {
      product.images = Object.values(product.images ?? []).filter((i) => i !== imagePath)
      set(ref(database, DB.products + '/' + id), product)
    }
  }

  const deleteAImage = (imagePath: string) => {
    const desertRef = storeRef(storage, imagePath)
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
        removeImageFromProduct(imagePath)
      })
      .catch((error) => {
        console.log(error)
        // Uh-oh, an error occurred!
      })
  }

  return (
    <div className='flex flex-col border-2 rounded-md'>
      <div className='container grid grid-cols-3 gap-2 mx-auto w-full'>
        <>
          {product?.images &&
            Object.values(product?.images)?.map((v, k) => {
              return <DisplayImage key={k} imagePath={v as string} />
            })}
        </>
      </div>
      <div className='flex w-full '>
        Upload images below 300kb
        {product && <input type='file' accept='image/*' onChange={handleChange} />}
      </div>
    </div>
  )

  function DisplayImage({ imagePath }: { imagePath: string }) {
    return (
      <div className='w-full rounded border-2 flex items-center justify-center group'>
        <img src={imagePath} className='w-32 h-auto' />

        <Button
          className='absolute hidden group-hover:block'
          onClick={() => deleteAImage(imagePath)}
        >
          Delete
        </Button>
      </div>
    )
  }
}

import { useSearchParams } from 'react-router-dom'
import { useDbReportsInvoiceList } from '../../base/useDbReports'

export function SalesReportsInvoiceList() {
  const [searchParams] = useSearchParams()

  const {
    invoices,
    invoiceTotalAmount,
    invoiceTotalTax,
    directInvoices,
    directInvoiceTotalAmount,
    directInvoiceTotalTax,
  } = useDbReportsInvoiceList(searchParams.get('year') ?? new Date().getFullYear().toString())

  // console.log({
  //   invoices,
  //   invoicesPerDate,
  //   invoiceTotalPerDate,
  //   invoiceTotalTaxPerDate,
  //   directInvoices,
  //   directInvoicesPerDate,
  //   directInvoiceTotalPerDate,
  //   directInvoiceTotalTaxPerDate,
  // })

  return (
    <div>
      <div className='flex flex-col mx-auto w-full items-center border-2 pb-2 bg-gray-50'>
        Report Year : {searchParams.get('year') ?? new Date().getFullYear()}
        <div>
          Show report for year:
          <select
            onChange={(e) => {
              searchParams.set('year', e.target.value)
              window.location.search = searchParams.toString()
            }}
          >
            {Array.from({ length: 10 }, (_, i) => i + 2023).map((y) => (
              <option key={y} value={y} selected={searchParams.get('year') === y.toString()}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='font-bold text-lg'>
        Daily Online Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>
      {/* invoice Total Per Date */}
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>InvoiceNo</th>
            <th>Amount</th>
            <th>Tax</th>
          </tr>
        </thead>
        <tbody>
          {invoices.length > 0 &&
            invoices.map((order) => (
              <tr key={'invTotal' + order.invoiceId} className='border'>
                <td key={order.invoiceId} className='border-r'>
                  {new Date(Number.parseInt(order.invoiceDate)).toLocaleDateString()}
                </td>
                <td className='border-r'>{order.invoiceId}</td>
                <td className='border-r text-right'>{order.totalAmount}</td>
                <td className='border-r text-right'>{order.taxAmount}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td className='text-right font-bold' colSpan={2}>
              Total
            </td>
            <td className='text-right font-bold'>{invoiceTotalAmount}</td>
            <td className='text-right font-bold'>{invoiceTotalTax}</td>
          </tr>
        </tfoot>
      </table>

      <div className='font-bold text-lg pt-10'>
        Daily Shop Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>
      {/* direct invoice Total Per Date */}

      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>InvoiceNo</th>
            <th>Amount</th>
            <th>Tax</th>
          </tr>
        </thead>
        <tbody>
          {directInvoices.length > 0 &&
            directInvoices.map((order) => (
              <tr key={'invDirTotal' + order.orderDate} className='border'>
                <td className='border-r'>
                  {new Date(order.orderDate as any).toLocaleDateString()}
                </td>
                <td className='border-r text-right'>{order.invoiceId}</td>
                <td className='border-r text-right'>{order.totalAmount}</td>
                <td className='border-r text-right'>{order.taxAmount}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td className='text-right font-bold' colSpan={2}>
              Total
            </td>
            <td className='text-right font-bold'>{directInvoiceTotalAmount}</td>
            <td className='text-right font-bold'>{directInvoiceTotalTax}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

import { createContext } from 'react'
import { Category } from '../model/Category'
import { Product } from '../model/Product'

export const DBContext = createContext<{
  categories: Category[]
  products: Product[]
}>({
  categories: [],
  products: [],
})

import { Outlet } from 'react-router-dom'
import Header from './Header'

export default function Layout() {
  return (
    <div className='flex flex-col h-full px-1 md:px-2 bg-gray-50'>
      <Header />
      <div className='flex flex-col h-full mx-0 md:mx-4'>
        <Outlet />
      </div>
    </div>
  )
}

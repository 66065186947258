import { useEffect, useState } from 'react'
import { useProductsByCategory } from '../../base/useDbHooks'
import { useDisplayCategories, useProductPricing } from '../../base/useDbProductPricingHooks'
import { Category } from '../../model/Category'
import { Product, ProductPricing } from '../../model/Product'
import { DataSnapshot } from 'firebase/database'

export function StockUpdate() {
  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()

  if (!displayCategories || !productsByCategory) {
    return null
  }

  return (
    <>
      <div className='uppercase text-xl font-bold w-full justify-center'>Stock Update</div>
      {displayCategories?.map((p) => {
        if (!productsByCategory[p.category]) {
          return null
        }

        return (
          <DisplayCategory
            key={p.category}
            category={p}
            products={productsByCategory[p.category]}
          />
        )
      })}
    </>
  )
}

function DisplayCategory({ category, products }: { category: Category; products: Product[] }) {
  return (
    <>
      <div className='uppercase text-lg font-bold pt-10'>{category.nameEn}</div>
      {products.map((product: Product) => (
        <DisplayProduct key={product.productId} product={product} />
      ))}
    </>
  )
}

function DisplayProduct({ product }: { product: Product }) {
  const { productPricingSnapshots } = useProductPricing(product.productId)

  if (!productPricingSnapshots || productPricingSnapshots.length === 0) {
    return null
  }

  return (
    <>
      <div className='font-semibold text-xl'>{product.productNameEn}</div>
      {productPricingSnapshots.map((productPricingSnapshot) => {
        return (
          <DisplayPricing
            key={productPricingSnapshot.key}
            productPricingSnapshot={productPricingSnapshot}
          />
        )
      })}
    </>
  )
}

function DisplayPricing({ productPricingSnapshot }: { productPricingSnapshot: DataSnapshot }) {
  const productPricing = productPricingSnapshot.val() as ProductPricing
  const { updatePricing } = useProductPricing()
  const [stock, setStock] = useState(productPricing.stock)
  const isStock = Number(productPricing.stock) > 0

  useEffect(() => {
    if (stock !== productPricing.stock) {
      productPricing.stock = stock
      updatePricing(productPricingSnapshot, productPricing)
    }
  }, [stock])

  return (
    <div className={isStock ? ' grid w-full grid-cols-3' : 'grid w-full grid-cols-3 bg-red-200'}>
      <span>{productPricing.unit}</span>
      <span className='text-right w-20 '>{productPricing.price} Yen</span>
      <span>
        <div className='flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1'>
          <button
            data-action='decrement'
            className=' bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none'
            onClick={() => {
              if (stock <= 0) {
                return
              }
              setStock(stock - 1)
            }}
          >
            <span className='m-auto text-2xl font-thin'>−</span>
          </button>
          <input
            type='number'
            className='outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none'
            name='custom-input-number'
            value={stock}
            min={0}
            onChange={(e) => {
              setStock(getAsNumber(e.target.value))
            }}
          ></input>
          <button
            data-action='increment'
            className='bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer'
            onClick={() => {
              setStock(stock + 1)
            }}
          >
            <span className='m-auto text-2xl font-thin'>+</span>
          </button>
        </div>
      </span>
    </div>
  )
}

function getAsNumber(value: any) {
  if (!value) {
    return 0
  }
  if (typeof value === 'string') {
    return parseInt(value)
  }
  return value
}

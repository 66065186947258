import { Page } from '../../base/Page'
import { useGetAllOrderedProducts } from '../../base/useDbOrders'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { useEffect, useState } from 'react'
import { ProductOrder } from '../../model/Order'

export default function DeliveryProducts() {
  const { orderedProducts } = useGetAllOrderedProducts()
  const [products, setProducts] = useState<ProductOrder[]>([])

  useEffect(() => {
    if (orderedProducts) {
      const sortedProducts = orderedProducts.sort((a, b) => a.nameEn.localeCompare(b.nameEn))
      setProducts(sortedProducts)
    }
  }, [orderedProducts])

  return (
    <Page title='Delivery Products'>
      <div className='w-full bg-white'>
        {(!products || products.length === 0) && <div>No orders</div>}
        {products && (
          <div className=' w-full'>
            <table className='w-full'>
              <head>
                <tr>
                  <th>Product Id</th>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Quantity</th>
                </tr>
              </head>
              <tbody>
                {products.map((orderedProduct) => {
                  return (
                    <tr key={orderedProduct.productId + orderedProduct.unit} className='border'>
                      <td className='border'>{orderedProduct.productId}</td>
                      <td className='border'>{orderedProduct.nameEn}</td>
                      <td className='border p-2'>{orderedProduct.unit}</td>
                      <td className='border float-right flex justify-center items-center w-full h-full'>
                        {orderedProduct.quantity}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Page>
  )
}

import { useSearchParams } from 'react-router-dom'
import { ProductOrderWithQuantity, useDbReportsMonthlyProduct } from '../../base/useDbReports'

export function SalesReportsMonthlyProduct() {
  const [searchParams] = useSearchParams()

  const { invoiceTotalQuantityPerProduct, directInvoiceTotalQuantityPerProduct } =
    useDbReportsMonthlyProduct(searchParams.get('year') ?? new Date().getFullYear().toString())

  // console.log({
  //   invoices,
  //   invoicesPerDate,
  //   invoiceTotalPerDate,
  //   invoiceTotalTaxPerDate,
  //   directInvoices,
  //   directInvoicesPerDate,
  //   directInvoiceTotalPerDate,
  //   directInvoiceTotalTaxPerDate,
  // })

  const invoiceTotals = Array.from(invoiceTotalQuantityPerProduct, ([key, value]) => {
    const products = Array.from(value, ([name, v]) => v).sort(
      (a: ProductOrderWithQuantity, b: ProductOrderWithQuantity) =>
        a.productId.localeCompare(b.productId),
    )
    return {
      date: key,
      products,
    }
  })
  invoiceTotals.sort((a, b) => a.date.localeCompare(b.date))

  const directInvoiceTotals = Array.from(directInvoiceTotalQuantityPerProduct, ([key, value]) => {
    console.log('key', key, value)
    const products = Array.from(value, ([name, v]) => v).sort(
      (a: any, b: any) => a.productId - b.productId,
    )
    return {
      date: key,
      products,
    }
  })

  console.log(directInvoiceTotalQuantityPerProduct)

  return (
    <div>
      <div className='flex flex-col mx-auto w-full items-center border-2 pb-2 bg-gray-50'>
        Report Year : {searchParams.get('year') ?? new Date().getFullYear()}
        <div>
          Show report for year:
          <select
            onChange={(e) => {
              searchParams.set('year', e.target.value)
              window.location.search = searchParams.toString()
            }}
          >
            {Array.from({ length: 10 }, (_, i) => i + 2023).map((y) => (
              <option key={y} value={y} selected={searchParams.get('year') === y.toString()}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='font-bold text-lg'>
        Daily Online Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>

      {/* Online invoice Total Per Month */}
      {invoiceTotals.length > 0 && (
        <div>
          {invoiceTotals.map(({ date, products }) => (
            <>
              <DisplayYearMonthTitle date={date} />
              <DisplayTable products={products} />
            </>
          ))}
        </div>
      )}

      <div className='font-bold text-lg pt-10'>
        Daily Shop Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>

      {/* direct invoice Total Per Month */}
      {directInvoiceTotals.length > 0 && (
        <div>
          {directInvoiceTotals.map(({ date, products }) => (
            <>
              <DisplayYearMonthTitle date={date} />
              <DisplayTable products={products} />
            </>
          ))}
        </div>
      )}
    </div>
  )
}

function DisplayYearMonthTitle({ date }: { date: string }) {
  return (
    <div className='flex justify-center'>
      <div className='text-center font-bold'>{date}</div>
    </div>
  )
}

function DisplayTable({ products }: { products: ProductOrderWithQuantity[] }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Product</th>
          <th>Unit</th>
          <th>Price</th>
          <th>Tax Rate</th>
          <th>Quantity</th>
          <th>Total</th>
          <th>Total Tax</th>
        </tr>
      </thead>
      <tbody>
        {products.map((p) => (
          <tr key={p.productId} className='border'>
            <td className='border-r'>{p.productId}</td>
            <td className='border-r'>{p.unit}</td>
            <td className='border-r text-right'>{p.price}</td>
            <td className='border-r text-right'>{p.taxRate}</td>
            <td className='border-r text-right'>{p.quantity}</td>
            <td className='border-r text-right'>{p.amount}</td>
            <td className='border-r text-right'>{p.tax}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

import { getDatabase, ref, set } from 'firebase/database'
import { firebaseApp } from '../firebase'
import { useParams } from 'react-router-dom'
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref as storeRef,
  uploadBytes,
} from 'firebase/storage'
import { Button } from 'flowbite-react'
import { DB } from '../DB'
import { useCategory } from '../base/useDbHooks'
import toast from 'react-hot-toast'

const database = getDatabase(firebaseApp)
const storage = getStorage(firebaseApp)

export default function CategoryImages() {
  const { category: id } = useParams()
  const { category } = useCategory(id)

  const handleChange = (e: any) => {
    e.preventDefault()
    if (!category) {
      return
    }
    const file = e.target?.files[0]
    if (!file) return

    const storageRef = storeRef(storage, `static/category/${id}-${file.name}`)
    uploadBytes(storageRef, file, {
      cacheControl: 'public, max-age=2592000, s-maxage=2592000',
    }).then((snapshot) => {
      e.target.value = ''
      getDownloadURL(snapshot.ref).then((url) => {
        category.images = url
        set(ref(database, DB.categories + '/' + category.category), category)
        toast.success('Image Uploaded')
      })
    })
  }

  const removeImageFromProduct = () => {
    if (category) {
      category.images = ''
      set(ref(database, DB.categories + '/' + category.category), category)
      toast.success('Image Deleted')
    }
  }

  const deleteAImage = (imagePath: string) => {
    const desertRef = storeRef(storage, imagePath)
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
        removeImageFromProduct()
      })
      .catch((error) => {
        console.log(error)
        // Uh-oh, an error occurred!
      })
  }

  return (
    <div className='flex flex-col border-2 '>
      <div className='container grid grid-cols-3 gap-2 mx-auto w-full'>
        {category?.images && <DisplayImage imagePath={category?.images} />}
      </div>
      <div className='w-full rounded'>
        {category && <input type='file' accept='image/*' onChange={handleChange} />}
      </div>
    </div>
  )

  function DisplayImage({ imagePath }: { imagePath: string }) {
    return (
      <div className='w-full rounded border-2 flex items-center justify-center group'>
        <img src={imagePath} className='w-32 h-auto' />

        <Button
          className='absolute hidden group-hover:block'
          onClick={() => deleteAImage(imagePath)}
        >
          Delete
        </Button>
      </div>
    )
  }
}

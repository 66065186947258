import React, { useEffect } from 'react'

import { Styles } from '../base/Styles'
import { Page } from '../base/Page'
import { firebaseApp } from '../firebase'
import { ref, getDatabase, set } from 'firebase/database'
import { useList } from 'react-firebase-hooks/database'
import { InputText } from '../base/InputText'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../base/validateResolver'
import { useForm } from 'react-hook-form'
import { Card } from 'flowbite-react'
import { DB } from '../DB'
import { DeliveryLocation } from '../model/Settings'
import toast from 'react-hot-toast'

const formSchema = Yup.object().shape({
  location: Yup.string().required(),
  addressEn: Yup.string().required(),
  addressJp: Yup.string().required(),
})

const database = getDatabase(firebaseApp)

export default function DeliveryLocations() {
  const [snapshots, loading, error] = useList(ref(database, DB.deliveryLocations))
  const [data, setData] = React.useState<DeliveryLocation[]>([])
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<DeliveryLocation>({ resolver: useYupValidationResolver(formSchema) })

  useEffect(() => {
    if (snapshots) {
      setData(snapshots.map((s) => s?.toJSON() as DeliveryLocation))
    }
  }, [snapshots])

  const onSubmit = (data: DeliveryLocation) => {
    set(ref(database, DB.deliveryLocations + '/' + data.location), data)
    reset()
    toast.success('Delivery Locations Saved')
  }

  return (
    <Page title='Delivery Locations'>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-row gap-2 items-center justify-center border-b-2  border-dotted py-1'>
            <InputText
              {...register('location')}
              name={'location'}
              label='Location'
              error={errors.location?.message}
            />
            <InputText
              {...register('addressEn')}
              name={'addressEn'}
              label='addressEn'
              error={errors.addressEn?.message}
            />
            <InputText
              {...register('addressJp')}
              name={'addressJp'}
              label='addressJp'
              error={errors.addressJp?.message}
            />
            <button type='submit' className={Styles.button.default}>
              Save
            </button>
          </div>
        </form>
        {error && <>Error: {error}</>}
        {loading && <span>List: Loading...</span>}
        {!loading && (
          <>
            <div className='grid grid-cols-4 gap-2 w-full'>
              {data?.map((d) => {
                // eslint-disable-next-line react/jsx-key
                return (
                  <>
                    <div className='py-1 border-b-2 flex items-center'>{d.location}</div>
                    <div className='py-1 border-b-2 flex items-center'>{d.addressEn}</div>
                    <div className='py-1 border-b-2 flex items-center'>{d.addressJp}</div>
                    <div className='py-1 border-b-2 flex items-center'>
                      <button
                        className={Styles.button.link}
                        onClick={() => {
                          setValue('location', d.location)
                          setValue('addressEn', d.addressEn)
                          setValue('addressJp', d.addressJp)
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className={Styles.button.link}
                        onClick={() => {
                          set(ref(database, DB.deliveryLocations + '/' + d.location), null)
                          toast.success('Delivery Locations Deleted')
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </>
                )
              })}
            </div>
          </>
        )}
      </Card>
    </Page>
  )
}

import {
  CellContext,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Styles } from '../../base/Styles'
import { Page } from '../../base/Page'
import { useNavigate } from 'react-router-dom'
import { Product, ProductPricing } from '../../model/Product'
import DisplayImage from './ProductImageDisplay'
import { format } from 'date-fns'
import { useProducts } from '../../base/useDbHooks'
import { useProductPricing } from '../../base/useDbProductPricingHooks'
import { DataSnapshot } from 'firebase/database'
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table'

const columnHelper = createColumnHelper<Product>()

const columns = [
  columnHelper.accessor('images', {
    header: () => 'Image',
    cell: (info) => {
      const images = info.getValue()
      if (images) {
        return <DisplayImage imagePath={images[0]} />
      }
    },
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('productId', {
    header: () => 'Id',
    cell: (info) => {
      const product = info.row.original as Product
      return (
        <>
          <div className='text-xs'>{product.productId}</div>
          <div>{product.category}</div>
          <div className='font-semibold'>{product.productNameEn}</div>
        </>
      )
    },
    footer: (info) => info.column.id,
  }),
  // columnHelper.accessor('category', {
  //   header: () => 'Category',
  //   cell: (info) => info.renderValue(),
  //   footer: (info) => info.column.id,
  // }),

  // columnHelper.accessor('productNameEn', {
  //   header: () => 'Product Name',
  //   cell: (info) => info.renderValue(),
  //   footer: (info) => info.column.id,
  // }),
  columnHelper.accessor('taxRate', {
    header: () => 'taxRate',
    cell: (info) => <>{info.renderValue()}%</>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor('createdDate', {
    header: () => 'Price',
    cell: (info) => {
      const product = info.row.original
      return <Pricings product={product} />
    },
    footer: (info) => info.column.id,
  }),
]
export default function Products() {
  const { products } = useProducts()
  const navigate = useNavigate()

  const table = useReactTable({
    data: products,
    columns: [
      ...columns,
      {
        header: 'Edit',
        cell: (info: CellContext<Product, number>) => {
          return (
            <>
              <button
                id='editButton'
                onClick={() => {
                  navigate('/products/' + info?.row?.getValue('productId'))
                }}
              >
                Edit
              </button>
            </>
          )
        },
      },
    ],
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Page title='Products'>
      <button
        type='button'
        className={Styles.button.default}
        onClick={() => navigate('/products/add')}
      >
        Add
      </button>
      {/* <div>
        <p>
          {error && <>Error: {error}</>}
          {loading && <span>List: Loading...</span>}
        </p>
      </div> */}
      <div className='w-full bg-white'>
        <Table className='border'>
          <Thead>
            <Tr className='border bg-gray-200 font-semibold'>
              <Td className='border'>Image</Td>
              <Td className='border'>Id</Td>
              <Td className='border'>TaxRate</Td>
              <Td className='border'>Price</Td>
              <Td className='border'>Edit</Td>
            </Tr>
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td className='border' key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
          {/* <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <Tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </Tr>
          ))}
        </tfoot> */}
        </Table>
      </div>
    </Page>
  )
}
function Pricings({ product }: { product: Product }) {
  const { productPricingSnapshots } = useProductPricing(product.productId)
  return (
    <div className='bg-gray-50'>
      {productPricingSnapshots?.map((productPricingSnapshot: DataSnapshot, index) => {
        const pricing = productPricingSnapshot.val() as ProductPricing
        const noStockClass =
          Number(pricing.stock) <= 0 ||
          (pricing.expireDate && new Date(pricing.expireDate) < new Date())
            ? 'text-red-500 font-bold '
            : ''
        const date = pricing.expireDate ? format(new Date(Number(pricing.expireDate)), 'PPp') : ''
        return (
          <div key={'tr' + index}>
            <span className={noStockClass}>{pricing.unit} - </span>
            <span className={noStockClass}>{pricing.price}円 - </span>
            {/* <span className={noStockClass}>{pricing.priceDiscount}</span> */}
            <span className={noStockClass}>{pricing.stock} </span>
            {date && <span className={noStockClass}>- {date}</span>}
          </div>
        )
      })}
    </div>
  )
}

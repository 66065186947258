export const Styles = {
  link: 'font-medium text-primary-600 hover:underline dark:text-primary-500',
  textSm: 'mb-4 text-base font-normal text-gray-500 dark:text-gray-400',

  menu: {
    active: 'hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white',
    notActive: 'text-gray-900',
    normal: ' w-full py-0 text-sm text-gray-700 dark:text-gray-200',
    box: 'flex gap-2 items-center px-2',
    text: ' block py-1 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white text-left',
  },

  table: {
    table:
      'w-full text-sm text-left text-gray-500 dark:bg-gray-800 dark:text-white dark:border-gray-700',
    header: 'text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400',
    tr: 'bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600',
    td: 'py-4 px-6',
    th: 'py-3 px-6',
  },
  button: {
    default:
      'text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-24',
    link: 'underline focus:ring-4 focus:ring-blue-300 rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-24',
  },
}

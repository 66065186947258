import { Card } from 'flowbite-react'
import { Page } from '../../base/Page'
import {
  useDisplayCategories,
  useProductPricing,
  useProductsByCategory,
} from '../../base/useDbProductPricingHooks'
import { Product, ProductPricing } from '../../model/Product'

export function WhatsAppMessage() {
  // List all the products and their pricings
  const { displayCategories } = useDisplayCategories()
  const { productsByCategory } = useProductsByCategory()
  return (
    <Page>
      <Card>
        {displayCategories?.map((category) => (
          <div key={category.category} className='cat_container dark:text-white'>
            <div className='uppercase'>*{category.nameEn}*</div>
            <ol className='product_results'>
              {productsByCategory[category.category]?.map((product: Product, index: number) => {
                return (
                  <DisplayPricings key={product.productId} product={product} index={index + 1} />
                )
              }) ?? null}
            </ol>
            <div> &nbsp; </div>
          </div>
        ))}
        <br />
        <div>Please order thru https://shop.royalbakes.jp/</div>
      </Card>
    </Page>
  )

  function DisplayPricings({ product, index }: { product: Product; index: number }) {
    const { productPricingSnapshots } = useProductPricing(product.productId)

    if (!productPricingSnapshots || productPricingSnapshots.length === 0) {
      return null
    }

    const productPricings = productPricingSnapshots
      .map((productPricingSnapshot) => {
        return productPricingSnapshot.val() as ProductPricing
      })
      .filter((productPricing) => {
        return productPricing.stock > 0
      })

    if (!productPricings || productPricings.length === 0) {
      return null
    }

    return (
      <>
        <li key={product.productId}>
          {index} - {product.productNameEn} -
          {productPricings.map((productPricing) => {
            return (
              <span key={productPricing.pricingId}>
                {productPricing.unit} - {productPricing.price}円{' '}
              </span>
            )
          })}
        </li>
      </>
    )
  }
}

import React, { useEffect } from 'react'

import { Styles } from '../base/Styles'
import { Page } from '../base/Page'
import { firebaseApp } from '../firebase'
import { ref, getDatabase, set } from 'firebase/database'
import { useObject } from 'react-firebase-hooks/database'
import { InputText } from '../base/InputText'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../base/validateResolver'
import { useForm } from 'react-hook-form'
import { Card } from 'flowbite-react'
import { DB } from '../DB'
import { OtherSettings } from '../model/Settings'
import toast from 'react-hot-toast'

const formSchema = Yup.object().shape({
  taxRate: Yup.number().required(),
  shippingTax: Yup.number().required(),
})

const database = getDatabase(firebaseApp)

export default function OtherSetting() {
  const [snapshot, loading, error] = useObject(ref(database, DB.settings))
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<OtherSettings>({ resolver: useYupValidationResolver(formSchema) })

  useEffect(() => {
    if (snapshot) {
      reset(snapshot?.toJSON() as OtherSettings)
    }
  }, [snapshot])

  const onSubmit = (data: OtherSettings) => {
    set(ref(database, DB.settings), data)
    console.log('save')
    toast.success('Settings Saved')
  }

  return (
    <Page title='Categories'>
      <Card>
        {error && <>Error: {error}</>}
        {loading && <span>List: Loading...</span>}
        {!loading && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <div className='flex flex-row gap-2 items-center justify-center border-b-2  border-dotted py-1'>
                <InputText
                  {...register('taxRate')}
                  name={'taxRate'}
                  label='taxRate'
                  error={errors.taxRate?.message}
                  className='w-20'
                />
                %
              </div> */}
              <div className='flex flex-row gap-2 items-center justify-center border-b-2  border-dotted py-1'>
                <InputText
                  {...register('shippingTax')}
                  name={'shippingTax'}
                  label='Shipping taxRate'
                  error={errors.shippingTax?.message}
                  className='w-20'
                />
                %
              </div>
              <button type='submit' className={Styles.button.default}>
                Save
              </button>
            </form>
          </>
        )}
      </Card>
    </Page>
  )
}

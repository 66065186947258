import { Page } from '../base/Page'
import { InputText } from '../base/InputText'
import { FormProvider, useForm } from 'react-hook-form'
import { Styles } from '../base/Styles'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../base/validateResolver'
import { getDatabase, ref, set } from 'firebase/database'
import { firebaseApp } from '../firebase'
import { useEffect } from 'react'
import { Card, Checkbox, Label } from 'flowbite-react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { DB } from '../DB'
import ReactQuill from 'react-quill'
import { useCategory } from '../base/useDbHooks'
import { Category } from '../model/Category'
import CategoryImages from './CategoryImages'

const formSchema = Yup.object().shape({
  category: Yup.string()
    .required()
    .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, 'Insert only chars'),
  nameEn: Yup.string().required(),
  nameJp: Yup.string().required(),
})

const database = getDatabase(firebaseApp)

export default function CategoryAddEdit() {
  const { category: id } = useParams()
  const navigate = useNavigate()

  const { category } = useCategory(id)

  const methods = useForm<Category>({ resolver: useYupValidationResolver(formSchema) })
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = methods

  const onSubmit = (data: Category) => {
    set(ref(database, DB.categories + '/' + data.category), data)
    toast.success('Category Saved')
    navigate('/settings/categories')
  }

  useEffect(() => {
    if (category) {
      reset(category)
    }
  }, [category])

  return (
    <Page title='Add/Edit Category'>
      <Card className='w-3/5'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-10 '>
              <div className='flex w-full'>
                <div className='flex flex-1 flex-col w-1/2 gap-2 '>
                  <div>
                    Category Id: <span className='text-lg font-bold'>{id}</span>
                  </div>
                  <InputText
                    {...register('category')}
                    name={'category'}
                    label='Category'
                    className='w-80'
                    error={errors.category?.message}
                  />
                </div>
                <CategoryImages />
              </div>
              <div className='flex flex-row w-full gap-2'>
                <InputText
                  {...register('nameEn')}
                  name={'nameEn'}
                  label='Name En'
                  className='w-80'
                  error={errors.nameEn?.message}
                />
                <InputText
                  {...register('nameJp')}
                  name={'nameJp'}
                  label='Name JP'
                  className='w-80'
                  error={errors.nameJp?.message}
                />
              </div>
              <div className='flex flex-row w-full gap-2'>
                <InputText
                  {...register('subMenuEn')}
                  name={'subMenuEn'}
                  label='Submenu En'
                  className='w-80'
                  error={errors.subMenuEn?.message}
                />
                <InputText
                  {...register('subMenuJp')}
                  name={'subMenuJp'}
                  label='Submenu  JP'
                  className='w-80'
                  error={errors.subMenuJp?.message}
                />
              </div>
              <div>
                <label
                  className={
                    errors?.descriptionEn
                      ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                      : 'block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  }
                >
                  Description English
                </label>
                <ReactQuill
                  theme='snow'
                  value={getValues('descriptionEn')}
                  onChange={(v) => setValue('descriptionEn', v)}
                />
              </div>
              <div>
                <label
                  className={
                    errors?.descriptionJp
                      ? 'block mb-2 text-sm font-medium text-red-700 dark:text-red-500'
                      : 'block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  }
                >
                  Description Japanese
                </label>
                <ReactQuill
                  theme='snow'
                  value={getValues('descriptionJp')}
                  onChange={(v) => setValue('descriptionJp', v)}
                />
              </div>
              <div className='flex flex-row w-full gap-2'>
                <div className='flex items-center gap-2'>
                  <Checkbox
                    id='accept'
                    defaultChecked={getValues().displayMenu}
                    onChange={(e) => {
                      setValue('displayMenu', e.target.checked)
                    }}
                  />
                  <Label htmlFor='accept'>Display in the menu</Label>
                </div>
                <InputText
                  {...register('displayOrder')}
                  name={'displayOrder'}
                  label='Display Order'
                  className='w-10'
                  error={errors.displayOrder?.message}
                />
              </div>
              <div className='flex flex-row w-full gap-2 items-center justify-end'>
                <button type='submit' className={Styles.button.default}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Card>
    </Page>
  )
}

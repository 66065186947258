export const DB = {
  users: 'users',
  cart: (userId?: string | null) => `users/${userId}/shoppingCart`,
  userBalance: (userId?: string | null) => `users/${userId}/balance`,
  currentOrders: 'currentOrders',
  directInvoice: 'directInvoice',
  invoice: 'invoices',
  invoiceProducts: 'invoiceProducts',
  cancelledOrders: 'cancelledOrders',
  cancelledProducts: 'cancelledProducts',
  invoiceId: 'settings/invoiceId',
  directInvoiceId: 'settings/directInvoiceId',
  categories: 'settings/categories',
  orderProducts: 'orderProducts',
  directOrderProducts: 'directOrderProducts',
  deliveryTimeslots: 'settings/deliveryTimeslots',
  deliveryCharges: 'settings/deliveryCharges',
  deliveryLocations: 'settings/deliveryLocations',
  pickupTimeslots: 'settings/pickupTimeslots',
  landmarks: 'settings/landmarks',
  settings: 'settings/otherSettings',
  products: 'products',
  productPricing: 'productPricing',
}

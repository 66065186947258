import { useSearchParams } from 'react-router-dom'
import { useDbReports } from '../../base/useDbReports'

export function SalesReports() {
  const [searchParams] = useSearchParams()

  const {
    // invoices,
    invoicesPerDate,
    invoiceTotalPerDate,
    invoiceTotalTaxPerDate,
    invoiceTotalAmount,
    invoiceTotalTax,
    // directInvoices,
    directInvoicesPerDate,
    directInvoiceTotalPerDate,
    directInvoiceTotalTaxPerDate,
    directInvoiceTotalAmount,
    directInvoiceTotalTax,
  } = useDbReports(searchParams.get('year') ?? new Date().getFullYear().toString())

  // console.log({
  //   invoices,
  //   invoicesPerDate,
  //   invoiceTotalPerDate,
  //   invoiceTotalTaxPerDate,
  //   directInvoices,
  //   directInvoicesPerDate,
  //   directInvoiceTotalPerDate,
  //   directInvoiceTotalTaxPerDate,
  // })

  const invoiceTotals = Array.from(invoiceTotalPerDate, ([key, value]) => ({
    date: key,
    amount: value,
  }))
  invoiceTotals.sort((a, b) => a.date.localeCompare(b.date))

  // const invoicesPerDay = Array.from(invoicesPerDate, ([key, value]) => ({
  //   date: key,
  //   orders: value,
  // }))

  // invoicesPerDay.sort((a, b) => a.date.localeCompare(b.date))

  const directInvoiceTotals = Array.from(directInvoiceTotalPerDate, ([key, value]) => ({
    date: key,
    amount: value,
  }))
  directInvoiceTotals.sort((a, b) => a.date.localeCompare(b.date))

  // const directInvoicesPerDay = Array.from(directInvoicesPerDate, ([key, value]) => ({
  //   date: key,
  //   orders: value,
  // }))

  // directInvoicesPerDay.sort((a, b) => a.date.localeCompare(b.date))

  return (
    <div>
      <div className='flex flex-col mx-auto w-full items-center border-2 pb-2 bg-gray-50'>
        Report Year : {searchParams.get('year') ?? new Date().getFullYear()}
        <div>
          Show report for year:
          <select
            onChange={(e) => {
              searchParams.set('year', e.target.value)
              window.location.search = searchParams.toString()
            }}
          >
            {Array.from({ length: 10 }, (_, i) => i + 2023).map((y) => (
              <option key={y} value={y} selected={searchParams.get('year') === y.toString()}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='font-bold text-lg'>
        Daily Online Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>
      {/* invoice Total Per Date */}
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Tax</th>
          </tr>
        </thead>
        <tbody>
          {invoiceTotalPerDate.size > 0 &&
            invoiceTotals.map(({ date, amount }) => (
              <tr key={'invTotal' + date} className='border'>
                <td key={date} className='border-r'>
                  {date}
                </td>
                <td className='border-r text-right'>{amount}</td>
                <td className='border-r text-right'>{invoiceTotalTaxPerDate.get(date)}</td>
                <td>
                  {invoicesPerDate.get(date)?.map((order) => {
                    return (
                      <div key={order.invoiceId} className=''>
                        {order.invoiceId} - ¥{order.totalAmount} - ¥{order.taxAmount}
                      </div>
                    )
                  })}
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td className='text-right font-bold'>Total</td>
            <td className='text-right font-bold'>{invoiceTotalAmount}</td>
            <td className='text-right font-bold'>{invoiceTotalTax}</td>
          </tr>
        </tfoot>
      </table>

      <div className='font-bold text-lg pt-10'>
        Daily Shop Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>
      {/* direct invoice Total Per Date */}

      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Total Tax</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {directInvoiceTotalPerDate.size > 0 &&
            directInvoiceTotals.map(({ date, amount }) => (
              <tr key={'invDirTotal' + date} className='border'>
                <td className='border-r'>{date}</td>
                <td className='border-r text-right'>{amount}</td>
                <td className='border-r text-right'>{directInvoiceTotalTaxPerDate.get(date)}</td>
                <td>
                  {directInvoicesPerDate.get(date)?.map((order) => {
                    return (
                      <div key={order.invoiceId}>
                        {order.invoiceId} - ¥{order.totalAmount} - ¥{order.taxAmount}
                      </div>
                    )
                  })}
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td className='text-right font-bold'>Total</td>
            <td className='text-right font-bold'>{directInvoiceTotalAmount}</td>
            <td className='text-right font-bold'>{directInvoiceTotalTax}</td>
          </tr>
        </tfoot>
      </table>

      {/* {invoicesPerDay.map(({ date, orders }) => (
        <div key={'invTax' + date} className='flex gap-3'>
          <div key={date}>{date}</div>
          <div>
            {orders.map((order) => {
              return (
                <div key={order.invoiceId} className='flex gap-3'>
                  <div key={order.invoiceId}>{order.invoiceId}</div>
                  <div>{order.totalAmount}</div>
                  <div>{order.taxAmount}</div>
                </div>
              )
            })}
          </div>
        </div>
      ))} */}

      {/* {directInvoicesPerDay.map(({ date, orders }) => (
        <div key={'invTax' + date} className='flex gap-3'>
          <div key={date}>{date}</div>
          <div>
            {orders.map((order) => {
              return (
                <div key={order.invoiceId} className='flex gap-3'>
                  <div key={order.invoiceId}>{order.invoiceId}</div>
                  <div>{order.totalAmount}</div>
                  <div>{order.taxAmount}</div>
                </div>
              )
            })}
          </div>
        </div>
      ))} */}
    </div>
  )
}

import { child, get, getDatabase, push, query, ref, remove, set } from 'firebase/database'
import { useEffect, useState } from 'react'
import { useList, useListVals, useObjectVal } from 'react-firebase-hooks/database'
import { DB } from '../DB'
import { firebaseApp } from '../firebase'
import { Order, ProductOrder } from '../model/Order'
import { DeliveryCharge, OtherSettings } from '../model/Settings'
import { useDeliveryCharges, useOtherSettings, useUpdateUserBalance } from './useDbHooks'

const database = getDatabase(firebaseApp)

export const useOrders = () => {
  const [ordersSnapshots] = useList(ref(database, DB.currentOrders))
  const [orders, setOrders] = useState<Order[]>([])

  useEffect(() => {
    if (ordersSnapshots) {
      const ods = ordersSnapshots.map((s) => s?.toJSON() as Order)
      // cats.sort((a, b) => a.displayOrder - b.displayOrder)

      setOrders(ods)
    }
  }, [ordersSnapshots])

  return { orders }
}

export const useOrder = (id: string) => {
  const [order] = useObjectVal<Order>(query(ref(database, DB.currentOrders + '/' + id)))

  return { order }
}

export const useOrderItems = (id?: string) => {
  const [orderProducts] = useListVals<ProductOrder>(ref(database, DB.orderProducts + '/' + id))

  return {
    orderProducts,
  }
}

export const useUpdateOrderItem = (productOrder: ProductOrder) => {
  set(
    ref(database, DB.orderProducts + '/' + productOrder.orderId + '/' + productOrder.key),
    productOrder,
  )
}

export const useRemoveOrderItem = (productOrder: ProductOrder) => {
  set(ref(database, DB.orderProducts + '/' + productOrder.orderId + '/' + productOrder.key), null)
}

export const useAddOrderItem = (productOrder: ProductOrder) => {
  const newKey = push(child(ref(database), DB.orderProducts + '/' + productOrder.orderId)).key
  productOrder.key = newKey
  set(
    ref(database, DB.orderProducts + '/' + productOrder.orderId + '/' + productOrder.key),
    productOrder,
  )
}

export const getDeliveryCharge = (totalAmount: number, deliveryCharges: DeliveryCharge[]) => {
  const deliveryCharge = deliveryCharges.find((d) => d.range >= totalAmount)
  return deliveryCharge?.deliveryCharge ?? 0
}

export const getTotalAndTax = (
  productOrders: ProductOrder[],
  otherSettings: OtherSettings | undefined,
  isDeliveryChangesApplicable: boolean,
  deliveryCharges?: DeliveryCharge[],
) => {
  // calculate subTotalAmount, taxAmount, deliveryCharge, deliveryChargeTaxAmount
  let subTotalAmount = 0
  let totalTaxAmount = 0
  const taxAmountByRate = new Map<number, number>()
  const deliveryCharge = 0
  const deliveryChargeTaxAmount = 0

  productOrders.forEach((p) => {
    const taxRate = (p.taxRate ?? 0) * 0.01
    const totalPrice = p?.price * p?.quantity

    const taxAmount = totalPrice > 0 ? (totalPrice / (1 + taxRate)) * taxRate : 0
    totalTaxAmount += taxAmount
    subTotalAmount += totalPrice
    const taxAmountByRateValue = taxAmountByRate.get(p.taxRate ?? 0) ?? 0
    taxAmountByRate.set(p.taxRate ?? 0, taxAmountByRateValue + taxAmount)
  })

  // if (isDeliveryChangesApplicable) {
  //   deliveryCharge = getDeliveryCharge(subTotalAmount + totalTaxAmount, deliveryCharges ?? [])
  //   const shippingTax = otherSettings?.shippingTax ?? 0
  //   deliveryChargeTaxAmount = deliveryCharge * shippingTax * 0.01
  // }

  console.log(
    'getTotalAndTax',
    subTotalAmount,
    totalTaxAmount,
    deliveryCharge,
    deliveryChargeTaxAmount,
  )

  const totalAmount = Number(subTotalAmount)

  return {
    subTotalAmount,
    totalTaxAmount,
    taxAmountByRate,
    deliveryCharge,
    deliveryChargeTaxAmount,
    totalAmount,
  }
}

export const useUpdateOrderTotals = (orderId: string) => {
  const { orderProducts } = useOrderItems(orderId)
  const { otherSettings } = useOtherSettings()
  const { deliveryCharges } = useDeliveryCharges()
  const { order } = useOrder(orderId)

  useEffect(() => {
    if (orderProducts && order) {
      const {
        subTotalAmount,
        totalTaxAmount,
        taxAmountByRate,
        deliveryCharge,
        deliveryChargeTaxAmount,
        totalAmount,
      } = getTotalAndTax(orderProducts, otherSettings, true, deliveryCharges)

      if (order.subTotal > 0) {
        if (order?.subTotal !== subTotalAmount) {
          set(ref(database, DB.currentOrders + '/' + orderId + '/subTotal'), subTotalAmount)
        }
        if (order?.taxAmount !== totalTaxAmount) {
          set(ref(database, DB.currentOrders + '/' + orderId + '/taxAmount'), totalTaxAmount)
        }
        if (order?.deliveryCharge !== deliveryCharge) {
          set(ref(database, DB.currentOrders + '/' + orderId + '/deliveryCharge'), deliveryCharge)
        }
        if (order?.deliveryChargeTaxAmount !== deliveryChargeTaxAmount) {
          set(
            ref(database, DB.currentOrders + '/' + orderId + '/deliveryChargeTaxAmount'),
            deliveryChargeTaxAmount,
          )
        }
        if (order?.totalAmount !== totalAmount) {
          set(ref(database, DB.currentOrders + '/' + orderId + '/totalAmount'), totalAmount)
        }

        const taxes = Array.from(taxAmountByRate, ([key, value]) => ({ rate: key, amount: value }))
        if (order?.taxes !== taxes) {
          set(ref(database, DB.currentOrders + '/' + orderId + '/taxes'), taxes)
        }
      }
    }
  }, [orderProducts, otherSettings, deliveryCharges])
}

export const useMoveOrderedProductsToOldProducts = (orderId: string) => {
  const { orderProducts } = useOrderItems(orderId)

  const moveProducts = () => {
    if (orderProducts) {
      set(ref(database, DB.invoiceProducts + '/' + orderId), orderProducts)
      remove(ref(database, DB.orderProducts + '/' + orderId))
    }
  }

  const moveProductsToCancelled = () => {
    if (orderProducts) {
      set(ref(database, DB.cancelledProducts + '/' + orderId), orderProducts)
      remove(ref(database, DB.orderProducts + '/' + orderId))
    }
  }

  return { moveProducts, moveProductsToCancelled }
}

export const calculateBalance = (totalAmount: number, paidAmount: number, userBalance: number) => {
  return (totalAmount - userBalance - paidAmount) * -1
}

export const useInvoiceIdGenerator = () => {
  const [invoices] = useObjectVal<number>(ref(database, DB.invoiceId))

  const getInvoiceId = () => {
    const invoiceId = invoices ?? 1
    set(ref(database, DB.invoiceId), invoiceId + 1)
    return invoiceId
  }

  return { getInvoiceId }
}

export const useUpdatePaidAmount = (orderId: string) => {
  const { order } = useOrder(orderId)
  const { moveProducts, moveProductsToCancelled } = useMoveOrderedProductsToOldProducts(orderId)
  const { userBalance, updateBalance } = useUpdateUserBalance(order?.userId ?? '')
  const { getInvoiceId } = useInvoiceIdGenerator()

  const updatePaid = (paidMethod: 'cash' | 'paypay', paidAmount: number) => {
    if (order) {
      const balanceAmount = calculateBalance(order.totalAmount, paidAmount, userBalance ?? 0)
      order.paidAmount = paidAmount
      order.balanceAmount = balanceAmount
      order.paidMethod = paidMethod

      console.log('useUpdatePaidAmount', order)

      order.invoiceDate = '' + Date.now()
      order.invoiceId = 'RBInv' + getInvoiceId()

      // find max invoice number

      set(ref(database, DB.invoice + '/' + order.invoiceId), order)
      remove(ref(database, DB.currentOrders + '/' + orderId))

      // move ordered products to old products
      moveProducts()
      updateBalance(balanceAmount)
    }

    // move to paid orders to paid orders
  }

  const updateCancelled = (cancelReason: string) => {
    if (order) {
      order.cancelledDate = '' + Date.now()
      order.cancelReason = cancelReason
      set(ref(database, DB.cancelledOrders + '/' + orderId), order)
      remove(ref(database, DB.currentOrders + '/' + orderId))

      moveProductsToCancelled()
    }

    // move to paid orders to paid orders
  }

  return { order, updatePaid, updateCancelled }
}

export const useGetAllOrderedProducts = () => {
  const [orderedProductsList, loading, error] = useObjectVal<any[]>(
    ref(database, DB.orderProducts),
  ) as any
  const [orderedProducts, setOrderedProducts] = useState<ProductOrder[]>([])

  useEffect(() => {
    if (orderedProductsList) {
      const keys = Object.keys(orderedProductsList ?? {})
      const productsMap = new Map()
      for (const key of keys) {
        const subKeys = Object.keys(orderedProductsList[key])
        for (const subKey of subKeys) {
          const product = orderedProductsList[key][subKey]
          const id = product.productId + '-' + product.unit
          if (productsMap.has(id)) {
            const existingProduct = productsMap.get(id)
            existingProduct.quantity += product.quantity
            productsMap.set(id, existingProduct)
          } else {
            productsMap.set(id, product)
          }
        }
      }
      setOrderedProducts(Array.from(productsMap.values()))
    }
  }, [orderedProductsList, loading])

  return { orderedProducts }
}

import { Dropdown, Navbar } from 'flowbite-react'
import { useEffect } from 'react'
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { isAdmin } from '../base/useDbHooks'

export default function Header() {
  const [user, loading] = useAuthState(auth)
  const [signOut] = useSignOut(auth)
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!loading && user === null && pathname !== '/signin') {
      navigate('/signin')
    }
    if (user && !isAdmin(user)) {
      navigate('/')
    }
  }, [user, pathname, loading, navigate])

  if (!user || !isAdmin(user)) return null

  return (
    <>
      <Navbar fluid={true} rounded={true}>
        <Navbar.Brand
          onClick={(e) => {
            e.preventDefault()
            navigate('/')
          }}
        >
          <div className='flex flex-col'>
            <span className='self-center whitespace-nowrap text-xl font-semibold dark:text-white'>
              Royal Bakes
            </span>
            <span className='self-center whitespace-nowrap text-sm dark:text-white'>Admin</span>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link onClick={() => navigate('/')} active={true}>
            Home
          </Navbar.Link>
          <Navbar.Link>
            <Dropdown
              label={
                <>
                  <button
                    id='dropdownReportsButton'
                    data-dropdown-toggle='dropdownReports'
                    className='flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white'
                    type='button'
                  >
                    Products
                    <svg
                      className='w-4 h-4 mx-1.5'
                      aria-hidden='true'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      ></path>
                    </svg>
                  </button>
                </>
              }
              size='sm'
              inline={true}
              pill={true}
              arrowIcon={false}
            >
              <Dropdown.Item onClick={() => navigate('/products')}>Products</Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/stock')}>Stock</Dropdown.Item>
            </Dropdown>
          </Navbar.Link>

          <Navbar.Link onClick={() => navigate('/orders')}>Orders</Navbar.Link>
          <Navbar.Link onClick={() => navigate('/users')}>Users</Navbar.Link>
          <Navbar.Link onClick={() => navigate('/deliveryProducts')}>Delivery</Navbar.Link>

          {/* Reports dropdown */}
          <Navbar.Link>
            <Dropdown
              label={
                <>
                  <button
                    id='dropdownReportsButton'
                    data-dropdown-toggle='dropdownReports'
                    className='flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white'
                    type='button'
                  >
                    Reports
                    <svg
                      className='w-4 h-4 mx-1.5'
                      aria-hidden='true'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      ></path>
                    </svg>
                  </button>
                </>
              }
              size='sm'
              inline={true}
              pill={true}
              arrowIcon={false}
            >
              <Dropdown.Item onClick={() => navigate('/reports/whatsappmsg')}>
                WhatsApp Messages
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/reports/salesReports')}>
                Daily Sales
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/reports/salesReportsMonthly')}>
                Monthly Sales
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/reports/salesReportsMonthlyProduct')}>
                Monthly Sales Product
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('/reports/salesReportsInvoice')}>
                Invoice List
              </Dropdown.Item>
            </Dropdown>
          </Navbar.Link>

          <Navbar.Link>
            <Dropdown
              label={
                <>
                  <button
                    id='dropdownAvatarNameButton'
                    data-dropdown-toggle='dropdownAvatarName'
                    className='flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white'
                    type='button'
                  >
                    Settings
                    <svg
                      className='w-4 h-4 mx-1.5'
                      aria-hidden='true'
                      fill='currentColor'
                      viewBox='0 0 20 20'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fillRule='evenodd'
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'
                      ></path>
                    </svg>
                  </button>
                </>
              }
              size='sm'
              inline={true}
              pill={true}
              arrowIcon={false}
            >
              <Dropdown.Item onClick={() => navigate('settings/categories')}>
                Categories
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('settings/deliveryTimeslot')}>
                Delivery Timeslots
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('settings/pickupTimeslot')}>
                Pickup Timeslots
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('settings/landmark')}>Landmark</Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('settings/deliveryCharges')}>
                Delivery Charges
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('settings/deliveryLocations')}>
                Delivery Locations
              </Dropdown.Item>
              <Dropdown.Item onClick={() => navigate('settings/otherSettings')}>
                Other Settings
              </Dropdown.Item>
            </Dropdown>
          </Navbar.Link>

          {user && (
            <Navbar.Link>
              <Dropdown
                label={
                  <>
                    <button
                      id='dropdownAvatarNameButton'
                      data-dropdown-toggle='dropdownAvatarName'
                      className='flex items-center text-sm font-medium text-gray-900 rounded-full hover:text-blue-600 dark:hover:text-blue-500 md:mr-0 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-white'
                      type='button'
                    >
                      {user?.photoURL && (
                        <img
                          className='mr-2 w-8 h-8 rounded-full'
                          src={user.photoURL}
                          alt='user photo'
                        />
                      )}
                      {user?.displayName ?? user?.email ?? user?.providerData[0].email}
                      <svg
                        className='w-4 h-4 mx-1.5'
                        aria-hidden='true'
                        fill='currentColor'
                        viewBox='0 0 20 20'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                          clipRule='evenodd'
                        ></path>
                      </svg>
                    </button>
                  </>
                }
                size='sm'
                inline={true}
                pill={true}
                arrowIcon={false}
              >
                <Dropdown.Header>
                  {user?.displayName && (
                    <span className='block text-sm truncate'>{user?.displayName}</span>
                  )}
                  <span className='block text-sm font-medium truncate text-gray-300'>
                    {user?.email ?? user?.providerData[0]?.email}
                  </span>
                </Dropdown.Header>
                <Dropdown.Item>Dashboard</Dropdown.Item>
                <Dropdown.Item>Settings</Dropdown.Item>
                <Dropdown.Item>Earnings</Dropdown.Item>
                <Dropdown.Item onClick={signOut}>Sign Out</Dropdown.Item>
              </Dropdown>
            </Navbar.Link>
          )}
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

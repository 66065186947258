import React, { forwardRef } from 'react'
interface PageProps {
  children: React.ReactNode
  title?: string
}
type PageRef = React.ForwardedRef<HTMLElement>
// eslint-disable-next-line react/display-name
export const Page = forwardRef(({ children, title, ...props }: PageProps, ref: PageRef) => {
  return (
    <section className='flex flex-col h-full m-2'>
      <h3 className='text-2xl font-bold dark:text-white'>{title}</h3>

      <div className='flex flex-col mx-auto w-full items-center'>{children}</div>
    </section>
  )
})

import React, { useEffect } from 'react'

import {
  CellContext,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Styles } from '../base/Styles'
import { Page } from '../base/Page'
import { useNavigate } from 'react-router-dom'
import { firebaseApp } from '../firebase'
import { ref, getDatabase } from 'firebase/database'
import { useList } from 'react-firebase-hooks/database'
import { Card } from 'flowbite-react'
import { DB } from '../DB'
import { User } from '../model/User'
import { format } from 'date-fns'

const columnHelper = createColumnHelper<User>()

const getValue = (info: CellContext<User, string | object | undefined>) => {
  try {
    return info.getValue()
  } catch (e) {
    console.log(e)
  }
  return ''
}

const columns = [
  columnHelper.accessor('info.id', {
    header: () => 'Id',
    cell: (info) => getValue(info),
  }),
  columnHelper.accessor('info.firstName', {
    header: () => 'FirstName',
    cell: (info) => getValue(info),
  }),
  columnHelper.accessor('info.lastName', {
    header: () => 'LastName',
    cell: (info) => getValue(info),
  }),
  columnHelper.accessor('info.location', {
    header: () => 'Chome',
    cell: (info) => getValue(info),
  }),
  columnHelper.accessor('info.address', {
    header: () => 'Address',
    cell: (info) => getValue(info),
  }),
  columnHelper.accessor('info.phoneNumber', {
    header: () => 'Phone Number',
    cell: (info) => getValue(info),
  }),
  columnHelper.accessor('info.landmark', {
    header: () => 'Landmark',
    cell: (info) => getValue(info),
  }),
  columnHelper.accessor('balance', {
    header: () => 'Balance',
    cell: (info) => {
      return <div className='font-bold'>{info.getValue()}</div>
    },
  }),
  columnHelper.accessor('info.createdDate', {
    header: () => 'created',
    cell: (info) => {
      const createdDate = getValue(info)
      return <div>{format(new Date(createdDate as string), 'PP p')}</div>
    },
  }),
]
const database = getDatabase(firebaseApp)

export default function Users() {
  const [snapshots, loading, error] = useList(ref(database, DB.users))

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [data, setData] = React.useState<User[]>([])
  const navigate = useNavigate()

  const table = useReactTable({
    data,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: [
      ...columns,
      {
        header: 'Edit',
        cell: (info: CellContext<User, number>) => {
          return (
            <>
              <button
                id='editButton'
                onClick={() => {
                  navigate('/users/' + info?.row?.original?.info?.id)
                }}
              >
                Edit
              </button>
            </>
          )
        },
      },
    ],
  })
  useEffect(() => {
    if (snapshots) {
      setData(snapshots.map((s) => s?.toJSON() as User))
    }
  }, [snapshots])

  return (
    <Page title='Users'>
      <button
        type='button'
        className={Styles.button.default}
        onClick={() => navigate('/products/add')}
      >
        Add
      </button>
      <div>
        <p>
          {error && <>Error: {error}</>}
          {loading && <span>List: Loading...</span>}
        </p>
      </div>
      <Card>
        <table className={Styles.table.table}>
          <thead className={Styles.table.header}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr className={Styles.table.tr} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td className={Styles.table.td} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          {/* <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot> */}
        </table>
      </Card>
    </Page>
  )
}
// function Pricings({ pricing }: { pricing: any }) {
//   return (
//     <>
//       <div className='grid grid-cols-4 gap-4 border-2 m-1 w-72'>
//         <div className='border-b font-semibold'>Unit</div>
//         <div className='border-b font-semibold'>Price</div>
//         <div className='border-b font-semibold'>Price Discount</div>
//         <div className='border-b font-semibold'>Stock</div>

//         {Object.values(pricing)?.map((pricing: any) => (
//           <>
//             <div className='border-b'>{pricing.unit}</div>
//             <div className='border-b'>{pricing.price}</div>
//             <div className='border-b'>{pricing.priceDiscount}</div>
//             <div className='border-b'>{pricing.stock}</div>
//           </>
//         ))}
//       </div>
//     </>
//   )
// }

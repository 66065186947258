import { Button, Card } from 'flowbite-react'
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
import { IoLogoGoogle } from 'react-icons/io5'

export default function SignIn() {
  const [signInWithGoogle] = useSignInWithGoogle(auth)
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()

  if (user) {
    navigate('/')
  }

  if (loading) {
    return (
      <div>
        <div>Initialising User...</div>
      </div>
    )
  }
  if (error) {
    return <>Error: {error}</>
  }

  return (
    <>
      <section className='flex h-full'>
        <div className='flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0 '>
          <Card>
            <div className='p-6 space-y-4 md:space-y-6 sm:p-8'>
              <h1 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>
                Sign In
              </h1>
              <div className='flex gap-1'>
                <Button
                  className='bg-[#4285F4] hover:bg-[#4285F4]/90 '
                  size='sm'
                  onClick={() => signInWithGoogle()}
                >
                  <IoLogoGoogle size='2em' />
                  Google
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </section>
    </>
  )
}

import { useSearchParams } from 'react-router-dom'
import { useDbReportsMonthly } from '../../base/useDbReports'

export function SalesReportsMonthly() {
  const [searchParams] = useSearchParams()

  const {
    invoiceTotalPerMonth,
    invoiceTotalTaxPerMonth,
    invoiceTotalAmount,
    invoiceTotalTax,
    directInvoiceTotalPerMonth,
    directInvoiceTotalTaxPerMonth,
    directInvoiceTotalAmount,
    directInvoiceTotalTax,
  } = useDbReportsMonthly(searchParams.get('year') ?? new Date().getFullYear().toString())

  // console.log({
  //   invoices,
  //   invoicesPerDate,
  //   invoiceTotalPerDate,
  //   invoiceTotalTaxPerDate,
  //   directInvoices,
  //   directInvoicesPerDate,
  //   directInvoiceTotalPerDate,
  //   directInvoiceTotalTaxPerDate,
  // })

  const invoiceTotals = Array.from(invoiceTotalPerMonth, ([key, value]) => ({
    date: key,
    amount: value,
  }))
  invoiceTotals.sort((a, b) => a.date.localeCompare(b.date))

  const directInvoiceTotals = Array.from(directInvoiceTotalPerMonth, ([key, value]) => ({
    date: key,
    amount: value,
  }))
  directInvoiceTotals.sort((a, b) => a.date.localeCompare(b.date))

  return (
    <div>
      <div className='flex flex-col mx-auto w-full items-center border-2 pb-2 bg-gray-50'>
        Report Year : {searchParams.get('year') ?? new Date().getFullYear()}
        <div>
          Show report for year:
          <select
            onChange={(e) => {
              searchParams.set('year', e.target.value)
              window.location.search = searchParams.toString()
            }}
          >
            {Array.from({ length: 10 }, (_, i) => i + 2023).map((y) => (
              <option key={y} value={y} selected={searchParams.get('year') === y.toString()}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className='font-bold text-lg'>
        Daily Online Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>

      {/* Online invoice Total Per Month */}
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Tax</th>
          </tr>
        </thead>
        <tbody>
          {invoiceTotalPerMonth.size > 0 &&
            invoiceTotals.map(({ date, amount }) => (
              <tr key={'invTotal' + date} className='border'>
                <td key={date} className='border-r'>
                  {date}
                </td>
                <td className='border-r text-right'>{amount}</td>
                <td className='border-r text-right'>{invoiceTotalTaxPerMonth.get(date)}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th className='text-right'>{invoiceTotalAmount}</th>
            <th className='text-right'>{invoiceTotalTax}</th>
          </tr>
        </tfoot>
      </table>

      <div className='font-bold text-lg pt-10'>
        Daily Shop Sales Reports ({searchParams.get('year') ?? new Date().getFullYear()})
      </div>

      {/* direct invoice Total Per Month */}
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Total Tax</th>
          </tr>
        </thead>
        <tbody>
          {directInvoiceTotalPerMonth.size > 0 &&
            directInvoiceTotals.map(({ date, amount }) => (
              <tr key={'invDirTotal' + date} className='border'>
                <td className='border-r'>{date}</td>
                <td className='border-r text-right'>{amount}</td>
                <td className='border-r text-right'>{directInvoiceTotalTaxPerMonth.get(date)}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Total</th>
            <th className='text-right'>{directInvoiceTotalAmount}</th>
            <th className='text-right'>{directInvoiceTotalTax}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

import {
  child,
  DataSnapshot,
  getDatabase,
  off,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  push,
  ref,
  remove,
  serverTimestamp,
  set,
} from 'firebase/database'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DB } from '../DB'
import { firebaseApp } from '../firebase'
import { Product, ProductPricing } from '../model/Product'
import { Category } from '../model/Category'
import { useListVals } from 'react-firebase-hooks/database'

const database = getDatabase(firebaseApp)

export const useCategories = () => {
  const [categories] = useListVals<Category>(ref(database, DB.categories))
  return { categories: categories ?? [] }
}

export const useDisplayCategories = () => {
  const [displayCategories, setDisplayCategories] = useState<Category[]>()
  const { categories } = useCategories()

  useEffect(() => {
    if (categories) {
      const filteredCategories = categories
      filteredCategories.sort((a, b) => a.displayOrder - b.displayOrder)
      setDisplayCategories(filteredCategories)
    }
  }, [categories])
  return { displayCategories }
}

export const useProducts = () => {
  const [snapshotsProduct, loading] = useListVals<Product>(ref(database, DB.products))
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    if (snapshotsProduct) {
      // const onlyStockProducts = snapshotsProduct.filter(
      //   (p) => p.pricing !== undefined && !!Object.keys(p.pricing),
      // )
      setProducts(snapshotsProduct)
    }
  }, [snapshotsProduct])

  return { products, loading }
}

export const useProductsByCategory = () => {
  const { products } = useProducts()
  const [productsByCategory, setProductsByCategory] = useState<any>([])

  useEffect(() => {
    if (products) {
      const groupByCategory = products.reduce((group: any, product) => {
        const { category } = product
        group[category] = group[category] ?? []
        group[category].push(product)
        return group
      }, {})

      // sort by product nameEn
      for (const category in groupByCategory) {
        groupByCategory[category].sort((a: Product, b: Product) =>
          a.productNameEn.localeCompare(b.productNameEn),
        )
      }

      setProductsByCategory(groupByCategory)
    }
  }, [products])

  return { productsByCategory }
}

export const useProductPricing = (productId?: string) => {
  const productPricingSnapshot = useMemo(
    () => ref(database, DB.productPricing + '/' + productId),
    [productId],
  )
  const [productPricingSnapshots, setProductPricingSnapshots] = useState<DataSnapshot[]>([])

  useEffect(() => {
    let _onChildAdded: any = undefined
    let _onChildChanged: any = undefined
    let _onChildRemoved: any = undefined

    const productPricingSnapshotsMap = new Map<string | null, DataSnapshot>()

    setTimeout(() => {
      _onChildAdded = onChildAdded(productPricingSnapshot, (snapshot) => {
        productPricingSnapshotsMap.set(snapshot.key, snapshot)
        const productPricingSnapshots = [...productPricingSnapshotsMap.values()].sort(
          (p1, p2) => p1.val().displayOrder - p2.val().displayOrder,
        )
        setProductPricingSnapshots(productPricingSnapshots)
      })
      _onChildChanged = onChildChanged(productPricingSnapshot, (snapshot) => {
        productPricingSnapshotsMap.set(snapshot.key, snapshot)
        const productPricingSnapshots = [...productPricingSnapshotsMap.values()].sort(
          (p1, p2) => p1.val().displayOrder - p2.val().displayOrder,
        )
        setProductPricingSnapshots(productPricingSnapshots)
      })
      _onChildRemoved = onChildRemoved(productPricingSnapshot, (snapshot) => {
        productPricingSnapshotsMap.delete(snapshot.key)
        const productPricingSnapshots = [...productPricingSnapshotsMap.values()].sort(
          (p1, p2) => p1.val().displayOrder - p2.val().displayOrder,
        )
        setProductPricingSnapshots(productPricingSnapshots)
      })
    }, 500)

    return () => {
      off(productPricingSnapshot, 'child_added', _onChildAdded)
      off(productPricingSnapshot, 'child_changed', _onChildChanged)
      off(productPricingSnapshot, 'child_removed', _onChildRemoved)
    }
  }, [productId])

  const addPricing = (productPricing: ProductPricing) => {
    productPricing.displayOrder = productPricing.displayOrder ?? 0
    productPricing.createdDate = productPricing.createdDate ?? serverTimestamp()
    productPricing.updatedDate = serverTimestamp()

    const newKey = push(child(ref(database), DB.productPricing + '/' + productId)).key
    productPricing.pricingId = newKey ?? new Date().getTime().toString()
    set(
      ref(database, DB.productPricing + '/' + productId + '/' + productPricing.pricingId),
      productPricing,
    )
  }

  const removePricing = useCallback((productPricingSnapshot: DataSnapshot) => {
    remove(productPricingSnapshot.ref)
  }, [])

  const updatePricing = useCallback(
    (productPricingSnapshot: DataSnapshot, productPricing: ProductPricing) => {
      productPricing.createdDate = productPricing.createdDate ?? serverTimestamp()
      productPricing.updatedDate = serverTimestamp()
      set(productPricingSnapshot.ref, productPricing)
    },
    [],
  )

  return { productPricingSnapshots, addPricing, removePricing, updatePricing }
}

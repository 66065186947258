import { Page } from '../base/Page'
import { InputText } from '../base/InputText'
import { FormProvider, useForm } from 'react-hook-form'
import { Styles } from '../base/Styles'
import * as Yup from 'yup'
import { useYupValidationResolver } from '../base/validateResolver'
import { useList, useObject } from 'react-firebase-hooks/database'
import { getDatabase, ref, serverTimestamp, set } from 'firebase/database'
import { firebaseApp } from '../firebase'
import { Category } from '../model/Category'
import { useEffect, useState } from 'react'
import { Card } from 'flowbite-react'
import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { ProductImage } from './products/ProductImages'
import { DB } from '../DB'
import { User, UserInfo } from '../model/User'
import { Tag, WithContext as ReactTags } from 'react-tag-input'
import { useDeliveryLocations } from '../base/useDbHooks'

const formSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  address: Yup.string().required(),
  location: Yup.string().required(),
  phoneNumber: Yup.string().required(),
})

const database = getDatabase(firebaseApp)

export default function UserEdit() {
  const [categoriesSnapshots] = useList(ref(database, DB.categories))
  const { id } = useParams()
  const [userSnapshots] = useObject(ref(database, DB.users + '/' + id))
  const navigate = useNavigate()
  const [user, setUser] = useState<User>()
  const { deliveryLocations } = useDeliveryLocations()

  const methods = useForm<UserInfo>({ resolver: useYupValidationResolver(formSchema) })
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods

  useEffect(() => {
    if (userSnapshots) {
      const userData = userSnapshots?.toJSON() as User
      if (userData) {
        reset(userData.info)
        const userCategories = userSnapshots.child('categories')?.toJSON()
        if (userCategories) {
          userData.categories = Object.values(userCategories)
        }
        setUser(userData)
      }
    }
  }, [userSnapshots, id])

  const onSubmit = (data: UserInfo) => {
    if (user) {
      user.info = data
      user.updatedDate = serverTimestamp()
      set(ref(database, DB.users + '/' + id), user)
      toast.success('User Saved')
      // navigate('/users')
    }
  }

  return (
    <Page title='Edit User'>
      <Card className='w-3/5'>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-7 '>
              <div className='flex w-full'>
                <div className='flex flex-1 flex-col w-1/2 gap-2 '>
                  <div>
                    User Id: <span className='text-lg font-bold'>{id}</span>
                  </div>
                </div>
                <ProductImage />
              </div>
              <div className='flex flex-row w-full gap-2'>
                <InputText
                  {...register('firstName')}
                  name='firstName'
                  label='First Name'
                  className='w-80'
                  error={errors.firstName?.message}
                />
                <InputText
                  {...register('lastName')}
                  name='lastName'
                  label='Last Name'
                  className='w-80'
                  error={errors.lastName?.message}
                />
              </div>
              <div className='flex flex-col'>
                <div className='flex gap-1 items-baseline'>
                  <div className='flex flex-col gap-1 items-baseline'>
                    <div className='text-sm font-medium text-gray-900 dark:text-white'>Address</div>
                    <select
                      {...register('location')}
                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-11 '
                    >
                      <option value=''>Select</option>
                      {deliveryLocations?.map((location) => (
                        <option
                          key={location.location}
                          value={location.location}
                          selected={user?.info?.location === location.location}
                        >
                          {location.addressEn}
                        </option>
                      ))}
                    </select>
                  </div>
                  <InputText
                    {...register('address')}
                    name={'address'}
                    maxLength={15}
                    label=' ,'
                    error={errors?.address?.message}
                  />
                  <InputText
                    {...register('phoneNumber')}
                    name='phoneNumber'
                    label='Phone number'
                    maxLength={11}
                    error={errors?.phoneNumber?.message}
                  />
                </div>
              </div>

              <div className='flex flex-col'>
                Categories
                <SelectCategories userCategories={user?.categories} />
              </div>

              <Orders />

              <div className='flex flex-row w-full gap-2 items-center justify-end'>
                <button type='submit' className={Styles.button.default}>
                  Save
                </button>
              </div>
            </div>
          </form>
        </FormProvider>
      </Card>
    </Page>
  )

  function SelectCategories({ userCategories }: { userCategories: string[] | undefined }) {
    const [tagsSelected, setTagsSelected] = useState<Tag[]>([])
    const [categories, setCategories] = useState<Category[]>([])

    useEffect(() => {
      if (categoriesSnapshots) {
        setCategories(categoriesSnapshots.map((s) => s?.toJSON() as Category))
      }
    }, [categoriesSnapshots])

    useEffect(() => {
      const cats = tagsSelected?.map((t) => t.id)
      cats.sort()
      if (user && user.categories?.toString() !== cats.toString()) {
        user.categories = cats
      }
    }, [tagsSelected])

    useEffect(() => {
      const cats = tagsSelected?.map((t) => t.id)
      cats.sort()
      const suggestions: Tag[] = categories?.map((category) => {
        return {
          id: category.category,
          text: category.nameEn,
        }
      })
      if (suggestions?.length > 0 && userCategories?.toString() !== cats.toString()) {
        const tags = userCategories?.reduce((tags: Tag[], c) => {
          const tag = suggestions.find((t) => t.id === c)

          if (tag) {
            tags.push(tag)
          }
          return tags
        }, [])
        setTagsSelected(tags ?? [])
      }
    }, [userCategories, categories])

    const KeyCodes = {
      comma: 188,
      enter: 13,
    }
    const delimiters = [KeyCodes.comma, KeyCodes.enter]

    const handleDelete = (i: number) => {
      setTagsSelected(tagsSelected.filter((tag, index) => index !== i))
    }

    const handleAddition = (tag: { id: string; text: string }) => {
      setTagsSelected([...tagsSelected, tag])
    }

    const handleDrag = (tag: { id: string; text: string }, currPos: number, newPos: number) => {
      const newTags = tagsSelected.slice()

      newTags.splice(currPos, 1)
      newTags.splice(newPos, 0, tag)

      // re-render
      setTagsSelected(newTags)
    }

    const handleTagClick = (index: number) => {
      console.log('The tag at index ' + index + ' was clicked')
    }

    return (
      <>
        <div>
          <ReactTags
            placeholder='Add Categories'
            tags={tagsSelected}
            suggestions={categories?.map((category) => {
              return {
                id: category.category,
                text: category.nameEn,
              }
            })}
            delimiters={delimiters}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            handleTagClick={handleTagClick}
            inputFieldPosition='bottom'
            autocomplete
          />
        </div>
      </>
    )
  }

  function Orders() {
    return (
      <>
        <table className='table-auto'>
          <thead>
            <tr>
              <th>Order Id</th>
              <th>Amount</th>
              <th>taxRate</th>
              <th>Total</th>
              <th>paid</th>
              <th>paidMethod</th>
              <th>deliveryTimeslot</th>
              <th>pickupTimeslot</th>
              <th>comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/* {fields.map((field, index) => (
            <>
              <InputText
                key={field.id + 'unit'}
                {...register(`pricing.${index}.unit`)}
                name={`pricing[${index}].unit`}
                type='text'
                error={e[`pricing[${index}].unit`]?.message}
              />
              <InputText
                key={field.id + 'price'}
                {...register(`pricing.${index}.price`)}
                name={`pricing[${index}].price`}
                error={e[`pricing[${index}].price`]?.message}
              />
              <InputText
                key={field.id + 'priceDiscount'}
                {...register(`pricing.${index}.priceDiscount`)}
                name={`pricing[${index}].priceDiscount`}
                type='number'
                error={e[`pricing[${index}].priceDiscount`]?.message}
              />
              <InputText
                key={field.id + 'stock'}
                {...register(`pricing.${index}.stock`)}
                name={`pricing[${index}].stock`}
                error={e[`pricing[${index}].stock`]?.message}
              />
              <Button
                className=' w-6'
                key={field.id + 'btn'}
                color='gray'
                pill={true}
                onClick={() => remove(index)}
              >
                X
              </Button>
            </>
          ))}
          <Button
            color='gray'
            pill={true}
            onClick={() => {
              append({} as ProductPricing)
            }}
          >
            Add
          </Button> */}
            </tr>
          </tbody>
        </table>
      </>
    )
  }
}

import { Styles } from '../base/Styles'
import { Page } from '../base/Page'
import { Card } from 'flowbite-react'
import { useCategories } from '../base/useDbHooks'
import { useNavigate } from 'react-router-dom'

export default function Categories() {
  const { categories } = useCategories()
  const navigate = useNavigate()

  return (
    <Page title='Categories'>
      <div>
        <button
          className={Styles.button.default}
          onClick={() => navigate('/settings/categories/add')}
        >
          Add Category
        </button>
      </div>
      <Card>
        <div className='grid grid-cols-6 gap-2 w-full'>
          <div className='py-1 border-b-2 flex items-center font-semibold'>Category</div>
          <div className='py-1 border-b-2 flex items-center font-semibold'>Name En</div>
          <div className='py-1 border-b-2 flex items-center font-semibold'>Name JP</div>
          <div className='py-1 border-b-2 flex items-center font-semibold'>Menu Display</div>
          <div className='py-1 border-b-2 flex items-center font-semibold'>Display Order</div>
          <div className='py-1 border-b-2 flex items-center font-semibold'>Edit</div>
          {categories?.map((d) => {
            return (
              <>
                <div className='py-1 border-b-2 flex items-center'>{d.category}</div>
                <div className='py-1 border-b-2 flex items-center'>{d.nameEn}</div>
                <div className='py-1 border-b-2 flex items-center'>{d.nameJp}</div>
                <div className='py-1 border-b-2 flex items-center'>
                  {d.displayMenu ? <div className='text-green-600 font-bold'>Yes</div> : 'No'}
                </div>
                <div className='py-1 border-b-2 flex items-center'>{d.displayOrder}</div>

                <div className='py-1 border-b-2 flex items-center'>
                  <button
                    className={Styles.button.link}
                    onClick={() => navigate('/settings/categories/' + d.category)}
                  >
                    Edit
                  </button>
                </div>
              </>
            )
          })}
        </div>
      </Card>
    </Page>
  )
}
